import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HktvChannels from "./HKTV_Channels";
import "../css/Play4Default.css";

import { Replay } from "vimond-replay";
import "vimond-replay/index.css";
import CompoundVideoStreamer from "vimond-replay/video-streamer/compound";
import axios from "axios";


function Play4Default() {
  const { type } = useParams();
  const checkNHKalive = "http://www3.nhk.or.jp/news/json16/tvnews.json";
  const getNHKLiveLink =
    "https://www3.nhk.or.jp/news/live/movie/player_live.json";

  const [playChannels, setPlayChannels] = useState([]);
  const [nhkGLive, setNhkGLive] = useState("");

  useEffect(() => {
    setNhkGLive("");
    switch (type) {
      case "prayforukraine":
        setPlayChannels(["Al-Jazeera-Eng", "CNN", "CNA", "SkyNewsUK"]);
        break;

      case "japan":
        axios
          .get(`https://cors.lwp.workers.dev/?${checkNHKalive}`)
          .then((res) => {
            if (res.data.viewFlg === true) {
              axios.get(getNHKLiveLink).then((res2) => {
                setNhkGLive(res2.data.mediaResource.url);
                setPlayChannels(["NTV-News24", "WW-JP", "NHK-World"]);
              });
            } else {
              setPlayChannels([
                "NHK-World",
                "NTV-News24",
                "WW-JP",
                "GunmaTV-jp",
              ]);
            }
          });
        break;

      default:
        setPlayChannels(["NHK-World", "NTV-News24", "WW-JP", "Al-Jazeera-Eng"]);
        break;
    }
  }, []);

  if (type === "japan") {
    return (
      <div className="play4default">
        <div className="play4Container">
          {nhkGLive !== "" ? (
            <div className="videoContainer">
              <Replay
                source={{
                  streamUrl: nhkGLive,
                  contentType: "application/x-mpegurl",
                }}
                initialPlaybackProps={{ isPaused: false, isMuted: true }}
                options={{
                  // controls: {
                  //   includeControls: [
                  //     "playPauseButton",
                  //     "timeline",
                  //     "timeDisplay",
                  //     "gotoLiveButton",
                  //     "volume",
                  //   ],
                  // },
                  videoStreamer: {
                    hlsjs: {
                      customConfiguration: {
                        capLevelToPlayerSize: true,
                        maxBufferLength: 45,
                      },
                    },
                    shaka: {
                      customConfiguration: {
                        streaming: {
                          bufferingGoal: 120,
                        },
                      },
                    },
                  },
                }}
              >
                <CompoundVideoStreamer />
              </Replay>
            </div>
          ) : null}
          {playChannels.map((ch) => (
            <div className="videoContainer">
              <Replay
                source={{
                  streamUrl: HktvChannels.tvChannels[ch].streamLink[0],
                  contentType: "application/x-mpegurl",
                }}
                initialPlaybackProps={{ isPaused: false, isMuted: true }}
                options={{
                  // controls: {
                  //   includeControls: [
                  //     "playPauseButton",
                  //     "timeline",
                  //     "timeDisplay",
                  //     "gotoLiveButton",
                  //     "volume",
                  //   ],
                  // },
                  videoStreamer: {
                    hlsjs: {
                      customConfiguration: {
                        capLevelToPlayerSize: true,
                        maxBufferLength: 45,
                      },
                    },
                    shaka: {
                      customConfiguration: {
                        streaming: {
                          bufferingGoal: 120,
                        },
                      },
                    },
                  },
                }}
              >
                <CompoundVideoStreamer />
              </Replay>
            </div>
          ))}
        </div>
      </div>
    );
  } else if (type === "prayforukraine") {
    return (
      <div className="play4default">
        <div className="play4Container">
          {playChannels.map((ch) => (
            <div className="videoContainer">
              <Replay
                source={{
                  streamUrl: HktvChannels.tvChannels[ch].streamLink[0],
                  contentType: "application/x-mpegurl",
                }}
                initialPlaybackProps={{ isPaused: false, isMuted: true }}
                options={{
                  // controls: {
                  //   includeControls: [
                  //     "playPauseButton",
                  //     "timeline",
                  //     "timeDisplay",
                  //     "gotoLiveButton",
                  //     "volume",
                  //   ],
                  // },
                  videoStreamer: {
                    hlsjs: {
                      customConfiguration: {
                        capLevelToPlayerSize: true,
                        maxBufferLength: 45,
                      },
                    },
                    shaka: {
                      customConfiguration: {
                        streaming: {
                          bufferingGoal: 120,
                        },
                      },
                    },
                  },
                }}
              >
                <CompoundVideoStreamer />
              </Replay>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Play4Default;
