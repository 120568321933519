import "./css/App.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React, { Suspense } from "react";
import { app } from "./components/firebase.js";
import { getAnalytics } from "firebase/analytics";

import { Center } from "@chakra-ui/react";

import Sidebar from "./Sidebar";
import Header from "./Header";
import Play4Default from "./components/Play4Default";
import JapanYTLive from "./components/JapanYTLive";
import WatchTV from "./WatchTV";
import TestYTStream from "./components/TestYTStream";

function App() {
  getAnalytics(app);

  return (
    <Router>
      <div className="App">
        <div className="appHeader">
          <Header />
        </div>
        <div className="appRow">
          <Switch>
            <Route path="/yt">
              <div className="fullContent">
                <TestYTStream />
              </div>
            </Route>
            <Route path="/watch/:channelID">
              <div className="leftSidebar">
                <Sidebar />
              </div>
              <div className="rightContent">
                <WatchTV />
              </div>
            </Route>
            <Route path="/play4boxes/:type">
              <div className="fullContent">
                <Play4Default />
              </div>
            </Route>
            <Route path="/jpview">
              <div className="fullContent">
                <JapanYTLive />
              </div>
            </Route>
            <Route path="/">
              <div className="leftSidebar">
                <Sidebar />
              </div>
              <div className="rightContent">
                <Center height="1fr">
                  <p>請選擇頻道</p>
                  <p>Choose Channel</p>
                </Center>
              </div>
            </Route>
            <Route path="*">
              <div className="leftSidebar">
                <Sidebar />
              </div>
              <div className="rightContent">
                <Center height={window.screen.height}>
                  <p>請選擇頻道</p>
                  <p>Choose Channel</p>
                </Center>
              </div>
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
}

export default App;
