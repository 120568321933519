import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { Replay } from "vimond-replay";
import "vimond-replay/index.css";
import CompoundVideoStreamer from "vimond-replay/video-streamer/compound";

import {
  Alert,
  AlertIcon,
  Link,
  AspectRatio,
  Container,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";

import { ExternalLinkIcon } from "@chakra-ui/icons";

import HktvChannels from "./components/HKTV_Channels";
import "./css/WatchTV.css";
import { Box } from "@material-ui/core";
//import { GetStreamLink } from "./components/GetStreamLink";

function WatchTV() {
  const { channelID } = useParams();
  const [slink, setSLink] = useState("");
  const [streamOffline, setStreamOffline] = useState(false);

  const redirectLink = "tv.dubdub.pro";
  const affectLinks = ["dubdubtv.web.app"];

  if (channelID === "OC1" || channelID === "OC2" || channelID === "OC3") {
    if (affectLinks.includes(window.location.hostname))
      window.location.href = `https://${redirectLink}/watch/${channelID}`;
  }

  function GetStreamLink(cid) {
    const corsApi = "https://cors.lwp.workers.dev/?";

    //Channel Dynamic Link
    const viu = "https://api.viu.now.com/p8/3/getLiveURL";

    switch (cid) {
      case "now331":
      case "now332":
      case "now333":
        axios
          .get(
            corsApi +
              `https://d1jithvltpp1l1.cloudfront.net/getLiveURL?channelno=${cid.replace(
                /^\D+/g,
                ""
              )}&format=HLS`,
            {
              mode: "no-cors",
              withCredentials: false,
              credentials: "same-origin",
              crossdomain: true,
            }
          )
          .then((res) => {
            return setSLink(res.data.asset.hls.adaptive[0]);
          });
        break;

      case "now096":
      case "now099":
        axios
          .post(corsApi + "https://api.viu.now.com/p8/3/getLiveURL", {
            contentId: cid.replace(/^\D+/g, ""),
            deviceId: "0000anonymous_user",
            deviceType: "IOS_PHONE",
          })
          .then((res) => {
            return setSLink(res.data.asset[0]);
          });
        break;

      case "OC1":
      case "OC2":
      case "OC3":
        axios
          .get(
            corsApi +
              `https://olympics.com/tokenGenerator?url=https://ott-dai-oc.akamaized.net/${cid.toUpperCase()}/master.m3u8&domain=${
                window.location.hostname
              }`,
            {
              mode: "no-cors",
              withCredentials: false,
              credentials: "same-origin",
              crossdomain: true,
            }
          )
          .then((res) => {
            return setSLink(res.data);
          });

        break;

      default:
        return null;
    }
  }

  useEffect(() => {
    setStreamOffline(false);
    if (HktvChannels.tvChannels[channelID].dynamicLink) {
      console.log(GetStreamLink(channelID));
      //setSLink(GetStreamLink(channelID));
    } else {
      setSLink(HktvChannels.tvChannels[channelID].streamLink[0]);
    }
  }, [channelID]);

  //Check Channel Online or Not...
  useEffect(() => {
    if (slink !== null) {
      axios
        .get(slink)
        .then((res) => {
          if (res.status !== 200) {
            console.log("Stream Offline with code: " + res.status);
            setStreamOffline(true);
          } else {
            setStreamOffline(false);
            console.log("Stream Online");
          }
        })
        .catch((err) => {
          console.log("Stream Offline: " + err);
          setStreamOffline(true);
        });
    }
  }, [slink]);

  function VideoPlayer(props) {
    return (
      <AspectRatio ratio={16 / 9}>
        <Replay
          source={{
            streamUrl: slink,
            contentType: "application/x-mpegurl",
          }}
          initialPlaybackProps={{ isMuted: false, isPaused: false }}
          options={{
            videoStreamer: {
              hlsjs: {
                customConfiguration: {
                  crossorigin: "anonymous",
                  cors: "anonymous",
                  capLevelToPlayerSize: true,
                  maxBufferLength: 45,
                },
              },
              shaka: {
                customConfiguration: {
                  streaming: {
                    bufferingGoal: 120,
                  },
                },
              },
            },
          }}
        >
          <CompoundVideoStreamer />
        </Replay>
      </AspectRatio>
    );
  }

  return (
    <div className="watchtv">
      <Container maxWidth="1fr">
        <div>
          <VideoPlayer />
        </div>
        <div className="watchTV_Bar">
          <div className="watchTV_NowplayingChannel">
            {/* <div className="watchTV_NowPlayingTitle">
              {streamOffline === true ? "❌ 頻道離線" : "✅ 現正直播"}
            </div> */}
            <div className="watchTV_NowPlayingChannel">
              {Object(HktvChannels.tvChannels)[channelID].channel_tcName ===
              Object(HktvChannels.tvChannels)[channelID].channel_enName
                ? Object(HktvChannels.tvChannels)[channelID].channel_tcName
                : Object(HktvChannels.tvChannels)[channelID].channel_tcName +
                  " - " +
                  Object(HktvChannels.tvChannels)[channelID].channel_enName}
            </div>
          </div>
          <Alert status="info" variant="left-accent">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>
                如果電腦使用Chrome流覽器而看不到頻道，可以嘗試安裝
              </AlertTitle>
              <AlertDescription display="block">
                <Link
                  href="https://chrome.google.com/webstore/detail/allow-cors-access-control/lhobafahddgcelffkeicbaginigeejlf/"
                  isExternal
                >
                  Allow CORS
                  <ExternalLinkIcon mx="2px" />
                </Link>
              </AlertDescription>
            </Box>
          </Alert>
        </div>
      </Container>
    </div>
  );
}

export default WatchTV;
