import React from "react";
import "boxicons";

import { Stack, Button, Box } from "@chakra-ui/react";
import LiveTvIcon from "@mui/icons-material/LiveTv";

import "./css/Header.css";

function Header() {
  return (
    <div className="header">
      <div className="headerContainer">
        <div className="headerTitle">
          <Box>
            <a href="/" target="_self">
              <box-icon name="tv" animation="tada" />
              DubDub watchTV
            </a>
          </Box>
        </div>
        <div className="headerLinksBar">
          <div className="headerLink">
            <Box>
              <Stack spacing={3} direction="row" align="center">
                <a href="/play4boxes/japan" target="_self">
                  <Button
                    leftIcon={<LiveTvIcon fontSize="20px" />}
                    colorScheme="teal"
                    variant="outline"
                    size="sm"
                  >
                    日本四格
                  </Button>
                </a>
                <a href="/play4boxes/prayforukraine" target="_self">
                  <Button
                    leftIcon={<LiveTvIcon fontSize="20px" />}
                    colorScheme="teal"
                    variant="outline"
                    size="sm"
                  >
                    國際四格
                  </Button>
                </a>
                <a href="/jpview" target="_self">
                  <Button
                    leftIcon="🇯🇵"
                    colorScheme="teal"
                    variant="outline"
                    size="sm"
                  >
                    日本風景
                  </Button>
                </a>
              </Stack>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
