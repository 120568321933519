import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import HktvChannels from "./components/HKTV_Channels";
import "./css/Sidebar.css";
import { db } from "./components/firebase.js";

function Sidebar() {
  const [channels, setChannels] = useState();

  useEffect(() => {
    db.collection("iptvChannels")
      .where("showCh", "==", true)
      .orderBy("channel_enName", "desc")
      .onSnapshot((snapshot) => {
        // setChannels(
        //   snapshot.docs.map((doc) => ({
        //     id: doc.id,
        //     data: doc.data(),
        //   }))
        // );

        console.log(snapshot);
      });
  }, []);

  console.log("sosad", channels);

  return (
    <div className="sidebar">
      <div className="sidebarContainer">
        {Object.entries(HktvChannels.tvChannels).map(([chID, chDetails]) => (
          <Link to={"/watch/" + chID} replace>
            <div className="sidebarRow">
              <div className="sidebarChannelIcon">
                <img
                  src={chDetails.channel_icon}
                  alt={chDetails.channel_enName}
                />
              </div>
              <div className="sidebarChannel">
                <div className="channelName">{chDetails.channel_tcName}</div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
