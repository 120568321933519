import React, { useEffect, useState } from "react";
import Randomizer from "react-randomizer";

import Fab from "@material-ui/core/Fab";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import {
  AspectRatio,
  Grid,
  GridItem,
  Stack,
  Button,
  Box,
  Select,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
} from "@chakra-ui/react";

import YTChannels from "./HKTV_Channels";
import "../css/JapanYTLive.css";
import "boxicons";
import { ArrowDownIcon, DeleteIcon, SettingsIcon } from "@chakra-ui/icons";

function JapanYTLive() {
  const session = window.sessionStorage;
  const [navBar, setNavBar] = useState(false);

  //Grid Box Settings
  const [gridBox, setGridBox] = useState("4");
  const [randomArr, setRandomArr] = useState([]);

  //console.log(window.sessionStorage.getItem("liveArr"));
  //window.sessionStorage.clear();

  useEffect(() => {
    if (session.getItem("liveArr") !== null) {
      setRandomArr(JSON.parse(session.getItem("liveArr")));
      setGridBox(session.getItem("liveGrid"));
    } else {
      let tempArr = Randomizer.randomizeArray(YTChannels.youtubeLive);
      setRandomArr(tempArr);
      session.setItem("liveArr", JSON.stringify(tempArr));
      session.setItem("liveGrid", "4");
    }
  }, [session]);

  const handleDrawer = () => {
    if (navBar === false) {
      setNavBar(true);
    } else {
      setNavBar(false);
      session.setItem("liveArr", JSON.stringify(randomArr));
      session.setItem("liveGrid", gridBox);
    }
  };

  const handleGridBoxChage = (e) => {
    setGridBox(e.target.value);
  };

  const handleRandomBtn = () => {
    setRandomArr(Randomizer.randomizeArray(YTChannels.youtubeLive));
    setNavBar(false);
  };

  const handleRefreshBtn = () => {
    session.setItem("liveArr", JSON.stringify(randomArr));
    session.setItem("liveGrid", gridBox);
    setNavBar(false);
    window.location.reload(false);
  };

  function handleRemoveBtn(i) {
    var newArr = randomArr.filter((item, index) => {
      return index !== i;
    });
    if (newArr.length <= gridBox) {
      newArr = newArr.concat(Randomizer.randomizeArray(YTChannels.youtubeLive));
    }
    setRandomArr(newArr);
    session.setItem("liveArr", JSON.stringify(newArr));
  }

  return (
    <div className="japanytlive">
      <div className="japanytlive-rootcontainer">
        <Fab
          aria-label="settings"
          className="fab-settings"
          size="small"
          color="primary"
          onClick={handleDrawer}
        >
          <SettingsIcon />
        </Fab>

        <Grid className={"japanytliveContainer" + gridBox} autoFlow="row dense">
          {randomArr.slice(0, gridBox).map((ch, index) => (
            <GridItem w="100%" h="100%">
              <Box>
                <Stack spacing={2} direction="row">
                  <Tag variant="subtle" colorScheme="whiteAlpha">
                    <TagLeftIcon boxSize="12px" as={ArrowDownIcon} />
                    <TagLabel>{ch.channelName}</TagLabel>
                  </Tag>
                  <Tag
                    size="sm"
                    variant="outline"
                    colorScheme="gray"
                    onClick={() => handleRemoveBtn(index)}
                  >
                    <TagLabel>刪除</TagLabel>
                    <TagRightIcon boxSize="12px" as={DeleteIcon} />
                  </Tag>
                </Stack>
                <AspectRatio ratio={16 / 9} w="100%">
                  <iframe
                    title="youtube"
                    src={`https://www.youtube.com/embed/live_stream?channel=${ch.channelID}&autoplay=1&disablekb=1&mute=1&fs=0&modestbranding=1&rel=0&controls=0`}
                    frameborder="0"
                  />
                </AspectRatio>
              </Box>
            </GridItem>
          ))}
        </Grid>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={navBar}
        onClose={handleDrawer}
        variant="temporary"
        className="japanytlive-drawer"
      >
        <div className="japanytlive-settings">
          <div className="japanytlive-settingHeader">設定</div>
          <div className="japanytlive-settingRow">
            現時共有<b>{YTChannels.youtubeLive.length}</b>條日本直播。
          </div>
          <div className="japanytlive-settingRow">
            <Stack spacing={2} direction="row" align="center">
              <span>直播顯示數量:</span>
              <Select
                native
                defaultValue={gridBox}
                value={gridBox}
                onChange={handleGridBoxChage}
                className="settings-gridBox"
                size="sm"
              >
                <option value={1}>1格</option>
                <option value={4}>4格</option>
                <option value={6}>6格</option>
                <option value={9}>9格</option>
              </Select>
            </Stack>
          </div>
          <div className="japanytlive-settingRow">
            <Stack spacing={2} direction="row" align="center">
              <Button colorScheme="yellow" size="sm" onClick={handleRandomBtn}>
                抽蛋 🥚
              </Button>
              <Button colorScheme="blue" size="sm" onClick={handleRefreshBtn}>
                重Load直播 📡
              </Button>
            </Stack>
          </div>
        </div>
      </SwipeableDrawer>
    </div>
  );
}

export default JapanYTLive;
